<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Profile </strong> Information </CCardHeader>
            <CCardBody>
              <CForm>
                <CInput
                  label="Leader"
                  horizontal
                  readonly
                  v-model="selectedLeader.fullName"
                >
                  <!-- <template #append>
                    <CButton color="primary" @click="onSearchLeader()">
                      Search
                    </CButton>
                  </template> -->
                </CInput>
                <CInput
                  label="Name"
                  horizontal
                  v-model="$v.obj.fullName.$model"
                  :isValid="checkIfValid('fullName')"
                  invalidFeedback="This is a required field and must be at least 2 characters"
                />
                <CInput label="Role" horizontal v-model="obj.role" readonly />
                <CInput
                  label="Email"
                  horizontal
                  v-model="$v.obj.email.$model"
                  :isValid="checkIfValid('email')"
                  invalidFeedback="This is a required field and must be valid e-mail address"
                  :readonly="isEmailReadOnly"
                />
                <CInput
                  label="Phone"
                  horizontal
                  v-model="$v.obj.phone.$model"
                  :isValid="checkIfValid('phone')"
                  invalidFeedback="This is a required field, must be valid numbers only, and between 8 to 11 digits"
                />
                <CInput
                  label="MyKad No"
                  horizontal
                  v-model="$v.obj.myKad.$model"
                  :isValid="checkIfValid('myKad')"
                  invalidFeedback="This is a required field"
                />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Is Reset Password?
                  </CCol>
                  <CCol sm="9">
                    <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked.sync="isResetPassword"
                    />
                  </CCol>
                </CRow>
                <CInput
                  description="Please specifiy password. Minimum password is 8 character with combonation of capital letter, numbers and special chracter."
                  label="Password"
                  horizontal
                  autocomplete="password"
                  v-model="plainPassword"
                  v-if="isResetPassword "
                />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Is Enable?
                  </CCol>
                  <CCol sm="9">
                    <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked.sync="obj.isEnable"
                    />
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="!isValid || submitted"
                @click="submit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <CButton
                class="ml-1"
                color="danger"
                :disabled="!isDirty"
                @click="reset"
              >
                Reset
              </CButton>
              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div>
      <CModal
        title="Search for Leader"
        :show.sync="leaderSearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="leaderList"
                  :fields="leaderFieldList"
                  column-filter
                  items-per-page-select
                  :items-per-page="10"
                  hover
                  sorter
                  pagination
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onLeaderSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
              <CCardFooter> </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>
  </div>
</template>

<script>
import ProfileApi from "../../lib/profileApi";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
  numeric,
  decimal,
  maxLength,
} from "vuelidate/lib/validators";

const leaderList = [];
const leaderFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "fullName", _style: "min-width:200px;" },
  { key: "email", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Profile",
  data: () => {
    return {
      isResetPassword: false,
      plainPassword: "",
      isEmailReadOnly: false,
      leaderSearchPopup: false,
      leaderList: leaderList.map((item, id) => {
        return { ...item, id };
      }),
      leaderFieldList,
      selectedLeader: {
        id: null,
        fullName: "",
      },
      infoList: [],
      api: new ProfileApi(),
      obj: {
        leaderId: null,
        fullName: "",
        role: "agent",
        email: "",
        phone: "",
        myKad: "",
      },
      loading: false,
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    self.resetObj();
  },
  validations: {
    obj: {
      fullName: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      myKad: {
        required,
      },
      role: {
        required,
      },
      phone: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11),
      },
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    onSubmit() {
      var self = this;
      var profileDto = {
        profile: self.obj,
        isResetPassword: self.isResetPassword,
        plainPassword: self.plainPassword,
      };

      if (!self.obj.id) {
        self.obj.role = "agent";
        this.api
          .createProfileAppUser(profileDto)
          .then((response) => {
            self.$router.push({ path: "/admins/AgentList" });
          })
          .catch(({ data }) => {
            self.toast(
              "Error",
              helper.getErrorMessage(data),
              "danger"
            );
          });
      } else {
        this.api
          .updateProfileAppUser(profileDto)
          .then((response) => {
            self.$router.push({ path: "/admins/AgentList" });
          })
          .catch(({ data }) => {
            self.toast(
              "Error",
              helper.getErrorMessage(data),
              "danger"
            );
          });
      }
    },
    onSearchLeader() {
      var self = this;
      self.refreshTableLeader();
      self.leaderSearchPopup = true;
    },
    onLeaderSelected(item) {
      var self = this;
      self.selectedLeader = item;
      self.obj.leaderId = item.id.toString();

      self.leaderSearchPopup = false;
    },
    refreshTableLeader() {
      var self = this;
      self.loading = true;
      self.api
        .getProfileList()
        .then((response) => {
          self.leaderList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.obj = {};
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      this.api
        .getCurrentProfile()
        .then((response) => {
          self.obj = response.result;
          if (self.obj.leaderId) {
            if (!self.obj.leader) {
              this.api
                .getProfile(self.obj.leaderId)
                .then((response) => {
                  self.selectedLeader = response.result;
                })
                .catch(({data}) => {
                  self.toast("Error", helper.getErrorMessage(data), "danger");
                });
            } else {
              self.selectedLeader = self.obj.leader;
            }
          }
        })
        .catch(({data}) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
      self.isResetPassword = false;
    },
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];

      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },
    reset() {
      // this.obj = this.getEmptyObj();
      this.resetObj();
      this.submitted = false;
      this.$v.$reset();
    },
    getEmptyObj() {
      return {
        leaderId: null,
        fullName: "",
        code: "",
        email: "",
        phone: "",
        myKad: "",
        role: "agent",
      };
    },
    submit() {
      if (this.isValid) {
        this.onSubmit();
        this.submitted = true;
      }
    },
    cancel() {
      //this.$router.push({ path: "/admins/AgentList" });
    },
  },
};
</script>


